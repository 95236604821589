import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import PortableText from '../components/portableText'
import FeatureBox from '../components/featureBox'
import Article from "../components/article";
import Share from "../components/share";
import {getFullUrl} from "../lib/helpers";

export const query = graphql`
query ProjectPageQuery($id: String) {
    project: sanityProject(_id: {eq: $id}) {
        title
        excerpt
        _rawBody
        slug {
            current 
        }
        features {
            title
            features
        }
        images {
            ...Image
        }
        mobileImages: images {
            ...MobileImage
        }
    }
}
`

class ProjectPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            project: (data || {}).project,
        }
    }

    render() {
        return (
            <>
                <SEO
                    title={this.state.project.title}
                    image={this.state.project.images ? this.state.project.images[0] : null}
                />


                <Article
                    title={this.state.project.title}
                    excerpt={this.state.project.excerpt}
                    byline={
                        <div className='md:hidden -mx-6'>
                            <FeatureBox content={this.state.project.features}/>
                        </div>
                    }
                    image={(
                        <Carousel>
                            {this.state.project.images && this.state.project.images.map((image, index) => (
                                <div key={`carousel-image-${index}`}>
                                    <Img fluid={image.asset.fluid} className='-mb-2'/>
                                </div>
                            ))}
                        </Carousel>
                    )}
                    mobileImage={(
                        <Carousel>
                            {this.state.project.mobileImages && this.state.project.mobileImages.map((image, index) => (
                                <div key={`carousel-image-${index}`}>
                                    <Img fluid={image.asset.fluid} className='-mb-2'/>
                                </div>
                            ))}
                        </Carousel>
                    )}
                    sidebar={(
                        <div className='hidden md:block'>
                            <FeatureBox content={this.state.project.features}/>
                        </div>
                    )}
                >
                    <div className="body-text">
                        {this.state.project._rawBody &&
                        <PortableText blocks={this.state.project._rawBody}/>}
                    </div>

                    <Share
                        url={getFullUrl(`/product/${this.state.project.slug.current}`)}
                        title={this.state.project.title}/>
                </Article>

            </>
        )
    }
}

export default ProjectPage
